<template>
  <div class="hello">
    <transition name="el-zoom-in-center">
      <el-table v-if="showTable"
        :data="list"
        stripe
        border
        @row-dblclick="rowDbclick"
        style="width: 100%">
        <el-table-column
          prop="task_tag"
          label="任务标签"
          :formatter="tagFormat"
          width="150">
        </el-table-column>
        <el-table-column
          prop="triggle_date"
          label="任务时间"
          :formatter="dateFormat"
          width="100">
        </el-table-column>
        <el-table-column
          prop="content"
          label="任务内容">
        </el-table-column>
        <el-table-column
          prop="key_word"
          label="任务关键词"
          width="220">
        </el-table-column>
        <el-table-column
          prop="button_name"
          label="按钮文案"
          width="140">
        </el-table-column>
        <el-table-column
          prop="tips_male"
          label="tips男">
        </el-table-column>
        <el-table-column
          prop="tips_female"
          label="tips女">
        </el-table-column>
      </el-table>
    </transition>
    <el-dialog title="任务配置" :visible.sync="showForm">
        <el-form class="form" ref="form" :model="taskDetail" label-width="180px">
            <el-form-item label="任务ID：">
                <el-input v-model="taskDetail.love_task_id" :disabled="true" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="任务日期：">
                <el-date-picker
                  v-model="taskTime"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="当日任务：">
                <el-input type="textarea" :rows="3" v-model="taskDetail.content" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item label="关键字：">
                <el-input v-model="taskDetail.key_word" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="按钮文案：">
                <el-input v-model="taskDetail.button_name" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="tips男：">
                <el-input type="textarea" :rows="3" v-model="taskDetail.tips_male" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item label="tips女：">
                <el-input type="textarea" :rows="3" v-model="taskDetail.tips_female" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item label="任务标签：">
                <el-radio v-model="taskTag" label="daily">日常任务</el-radio>
                <el-radio v-model="taskTag" label="holiday">节日任务</el-radio>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{taskDetail.isAdd ? '创建':'立即修改'}}</el-button>
                <el-button @click="deleteSubmit">删除</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-button
        class="add-btn"
        type="primary"
        icon="el-icon-plus"
        circle
        @click="addBtnClick">
    </el-button>
  </div>
</template>

<script>
import {taskList, taskCreation, taskModification, taskDeletion} from '@/api/loveTask.js';
import moment from 'moment';

export default {
  name: 'Daily',
  props: {

  },
  data () {
    return {
      showTable: false,
      showForm: false,
      list: [],
      taskDetail: {},
      taskTag: '',
      taskTime: ''
    };
  },

  methods: {
    tagFormat (row, column) {
      let tag = row[column.property];
      if (tag.indexOf('daily') > -1) {
        return '日常任务';
      } else {
        return '节日任务';
      }
    },

    dateFormat (row, column) {
      let date = row[column.property];
      if (date == undefined) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD');
    },
    rowDbclick (row) {
      console.log('双击了某一行', row);
      this.showForm = true;
      this.taskDetail = {
        isAdd: false,
        love_task_id: row.love_task_id,
        content: row.content,
        key_word: row.key_word,
        tips_male: row.tips_male,
        tips_female: row.tips_female,
        button_name: row.button_name
      };
      this.taskTime = row.triggle_date;
      this.taskTag = row.task_tag;
    },

    addBtnClick () {
      this.showForm = true;
      this.taskDetail = {
        isAdd: true,
        content: '',
        key_word: '',
        tips_male: '',
        tips_female: '',
        button_name: ''
      };
      this.taskTag = '';
      this.taskTime = '';
    },

    onSubmit () {
      let that = this;
      let taskDetail = that.taskDetail;
      let reqData = {
        triggle_date: moment(that.taskTime).format('YYYY-MM-DD'),
        content: taskDetail.content,
        key_word: taskDetail.key_word,
        tips_male: taskDetail.tips_male,
        tips_female: taskDetail.tips_female,
        task_tag: that.taskTag,
        button_name: taskDetail.button_name
      };
      if (taskDetail.isAdd) {
        taskCreation(reqData)
          .then((res) => {
            console.log('添加成功：', res);
            that.showForm = false;
            that.getListData();
          })
          .catch((error) => {
            console.log('添加失败：', error);
          });
      } else {
        reqData.love_task_id = taskDetail.love_task_id;
        taskModification(reqData)
          .then((res) => {
            console.log('编辑成功：', res);
            that.showForm = false;
            that.getListData();
          })
          .catch((error) => {
            console.log('编辑失败：', error);
          });
      }

    },

    deleteSubmit () {
      let that = this;
      taskDeletion({
        love_task_id: that.taskDetail.love_task_id
      })
        .then((res) => {
          console.log('删除成功：', res);
          that.showForm = false;
          that.getListData();
        })
        .catch((error) => {
          console.log('删除失败：', error);
        });
    },

    getListData () {
      let that = this;
      taskList({type: 'operator'})
        .then((res) => {
          console.log('每日任务列表：', res);
          that.list = res.data;
          that.showTable = true;
        })
        .catch((error) => {

        });
    }

  },

  beforeCreate () {},
  created () {
    this.getListData();
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.add-btn{
    position: fixed;
    width: 60px;
    height: 60px;
    right: 50px;
    bottom: 50px;
}

</style>
